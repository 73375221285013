   a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
    a, a:hover {
      text-decoration: none !important;
     }
     p {
      color: #b3b3b3;
      font-weight: 300; }
      .navbar-nav > li > a {
        color: #2f2f32 !important;
        font-size: 16px;
        font-weight: 400 !important;
        transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        
        margin: 0 7px -1px;
        position: relative; }
        .navbar-nav > li > a i {
          color: #454545 !important;
          font-size: 16px;
          margin-left: 6px; }
      .navbar-nav > li:last-child > a {
        padding-right: 0 !important;
        margin-right: 0; }
      .navbar-nav > li > a.active,
      .navbar-nav > li > a:hover {
        color: #4783f3 !important; }
      .navbar-nav li.drop-link {
        position: relative; }
    
    .navbar-nav.active > li > a {
      opacity: 0;
      visibility: hidden; }
    
    .navbar-collapse {
      position: relative; }
    
    .dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      background: #fff;
      width: 240px;
      -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
      background-color: #fff;
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      z-index: 2;
      transition: all 0.15s ease-in-out;
      -moz-transition: all 0.15s ease-in-out;
      -webkit-transition: all 0.15s ease-in-out;
      -o-transition: all 0.15s ease-in-out; }
      .dropdown li {
        position: relative;
        display: block;
        padding: 0px 20px;
        border: none; }
        .dropdown li a {
          position: relative;
          display: block;
          padding: 12px 0;
          color: #464749;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          border-bottom: 1px solid #e9ecef; }
        .dropdown li a:hover {
          color: #4783f3; }
        .dropdown li .dropdown.level2 {
          top: 0px;
          margin-top: 0;
          left: 100%;
          margin-left: 10px; }
        .dropdown li:hover .dropdown.level2 {
          margin-left: 0; }
        .dropdown li:last-child a {
          border-bottom: none; }
        .dropdown li.drop-link > a:after {
          content: '';
          display: block;
          position: absolute;
          right: 0px;
          top: 50%;
          margin-top: -4px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px 0 4px 6px;
          border-color: transparent transparent transparent #dee2e6; }
    
    li:hover > .dropdown {
      visibility: visible;
      opacity: 1;
      margin-top: -20px; }

      .navbar .dropdown ul {
        display: block;
        position: absolute;
        left: 14px;
        top: calc(100% + 30px);
        margin: 0;
        padding: 10px 0;
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        background: #fff;
        box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
        transition: 0.3s;
        border-top: 2px solid #1bbd36;
      }
      
      .navbar .dropdown ul li {
        min-width: 200px;
      }
      
      .navbar .dropdown ul a {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        color: #111;
      }
      
      .navbar .dropdown ul a i {
        font-size: 12px;
      }
      
      .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
        color: #1bbd36;
      }
      
      .navbar .dropdown:hover > ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
      }
    
.header {
    .navbar {
        .navbar-nav {
            align-items: center;
        }
    }
}

   